@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "MonaSans-Regular";
  src: url("../assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "MonaSans-Medium";
  src: url("../assets/fonts/Montserrat/static/Montserrat-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "MonaSans-SemiBold";
  src: url("../assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "MonaSans-Bold";
  src: url("../assets/fonts/Montserrat/static/Montserrat-Bold.ttf");
  font-weight: 700;
}
:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #000000;
  --txt-white: #fff;
  --main-color: #349eff;

  --second-color: #43964d;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62SEMff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #019707;
  --second-color-orange: #4caf50;

  --sidebar-width: 50px;
  --border-radius: 8px;
  --header-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1);

  --scroll-bar-track: var(--main-color);
  // --scroll-bar-track: #ffdb61;
  --scroll-bar-bg: #2d2d2d;

  --table-border: #e4e4e4;
  --table-border-dark: #424242;
  --table-th-bg: #eeeeee;
  --table-th-border: #d4d4d4;

  --input-color: #666666;
  --input-border-dark: #8d8d8d;
  --place-holder: #666666;
  --field-border-dark: #eeeeee;
  --field-border: #b1b1b1;
  --checkbox-select: #1976d2;
  --text-input-field: --place-holder;
  --text-disable-dark: rgb(219 219 219 / 38%);
  --text-disable: rgb(60 60 60 / 60%);

  --title-text-color: #5db64c;
  --link-text: #000000;

  --accr-base-color: #ffffff;
  --accr-base-color-dark: #383838;
  --accr-button-color: #f1f1f1;
  --accr-button-color-dark: #202020;

  --lable-bold-color: #000000;
  --white-place-holder: #a0a0a0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

a {
  text-decoration: none;
  color: #0784c3;
  &:hover,
  &.active {
    color: var(--link-text);
  }
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;

  // &:focus {
  //     border: 2px solid transparent;
  // }
  &:focus-visible {
    outline: none !important;
  }
}

.page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
}

.card {
  word-wrap: break-word;
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  border: none;
  .card__header {
    h3 {
      position: relative;
      padding-bottom: 10px;
      font-size: 18px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 25px;
        height: 2px;
        display: inline-block;
        background: #5db64c;
      }
    }
  }
}

.card__border {
  border: 2px solid;
  border-color: var(--second-color);
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
  padding-bottom: 16px;
}
.cus-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// .card>div~div {
//     margin-top: 20px;
// }

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

.theme-color {
  color: #5db64c;
}
.btn-wrap {
  display: flex;
  gap: 10px;
}
button.btn,
a.btn {
  background: var(--main-color);
  min-width: 120px;
  font-size: 16px;
  border: 1px solid var(--second-color);
  border-radius: 23px;
  padding: 13px 20px;
  line-height: 1;
  color: #ffffff;
  width: auto;
  background-image: linear-gradient(-108deg, #5db64c, #115d37);
  &:hover {
    color: #fff;
  }
}
.theme-mode-light button.btn {
  // color: var(--txt-color);
  &:hover,
  &:active,
  &:focus {
    // color: var(--txt-white);
    // background: var(--second-color);
    // border-color: var(--second-color);
  }
}
.theme-mode-dark button.btn {
  &:hover,
  &:active,
  &:focus {
    color: var(--txt-white);
    background: var(--second-color);
    border-color: var(--second-color);
  }
}

// #loom-companion-mv3 + iframe, svg#SvgjsSvg1119 {
//     display: none;
// }

iframe,
svg#SvgjsSvg1119 {
  display: none;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: var(--input-color) !important;
  opacity: 1 !important;
}

::placeholder {
  color: var(--place-holder) !important;
  opacity: 1 !important;
}

.MuiCheckbox-root {
  color: var(--input-color) !important;
  &.Mui-checked {
    color: var(--checkbox-select) !important;
  }
}

.input-wrape .MuiSelect-select,
.input-wrape .MuiInputBase-input {
  color: var(--text-input-field);
}

.input-wrape .MuiInputBase-formControl {
  border-radius: 34px;
}

.MuiTypography-root {
  color: var(--txt-color);
}

.MuiFormHelperText-root {
  display: none;
}
.card.dark-box {
  background: var(--main-bg-dark);
}
.fauset-alert {
  background: #cd4f38;
  border-color: #cd4f38;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  padding: 16px 48px 16px 21px;
  width: 70%;
  position: relative;
  .alert-close-btn {
    min-width: 25px;
    position: absolute;
    right: 7px;
    top: 7px;
  }
}
.wallet-add-sec {
  width: 70%;
}
.quis-sec {
  margin-bottom: 35px;
  p {
    margin-bottom: 0;
  }
  h5 {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 1256px) {
  .btn {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    padding: 10px 15px;
  }
  .header__logo {
    padding: 0;
  }
  .layout__content-main {
    padding: 2rem 1rem;
  }
  .card {
    padding: 15px;
    .card__header {
      h3 {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header__right {
    align-items: center;
  }
  .container-wrape .title-text {
    font-size: 2rem;
  }
  .search-component.desk {
    display: none;
  }
  #search-toggle-wrape {
    display: block;
    position: relative;
    .mob-search-icon {
      display: flex;
      background: var(--main-color);
      align-items: center;
      line-height: 1;
      padding: 5px;
      border-radius: 5px;
      i {
        line-height: 1;
        font-size: 20px;
        color: #fff;
      }
    }
    .search-mob-wrape {
      display: none;
      position: absolute;
      background: var(--main-bg);
      z-index: 5;
      right: 0;
      border-radius: 10px;
      padding: 10px;
      box-shadow: var(--box-shadow-light);
    }
    &.show {
      .search-mob-wrape {
        display: block;
      }
    }
  }
  .button_wrap {
    flex-wrap: wrap;
    button.btn,
    a.btn {
      white-space: nowrap;
      font-size: 15px;
    }
  }
}

.privacy-main {
  position: relative;
  z-index: 999;
  padding: 64px 20px;
  .privacy-container {
    max-width: 1062px;
    width: 100%;
    margin: 0 auto;
    padding: 0 12px;

    .privacy-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background: #081d35;
      background: var(--main-bg);
      padding: 40px 32px;
      border-radius: 12px 12px 0 0;
      .privacy-header-title h5 {
        font-size: 1.875rem;
        margin: 0 0 8px;
        color: var(--txt-color);
      }
      .privacy-header-title p {
        font-size: 14.4992px;
        line-height: 21px;
        margin: 0;
        color: var(--txt-color);
      }
      .privacy-header-logo {
        height: 80px;
      }
    }
    .privacy-content-sec {
      padding: 32px;
      background: #ffffff;
      color: var(--txt-color);
      border-radius: 0 0 12px 12px;
    }
    .privacy-content-sec p {
      color: var(--txt-color);
    }
  }
}
.layout.theme-mode-light .privacy-content-sec {
  background: #2d2d2d;
}
.layout.theme-mode-light .privacy-content-sec p,
.layout.theme-mode-light .privacy-content-sec h3,
.layout.theme-mode-light .privacy-content-sec .list-unstyled li {
  color: #ffffff;
}
.right-arrow-td {
  text-align: center;
}
.right-arrow-td svg {
  width: 25px;
}
.table-wrapper tbody > tr:hover .right-arrow-td svg {
  fill: #ffffff;
}
.pagination-sec {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  .pagination-ul {
    display: flex;
    gap: 0.25rem;
    .pagination-li span {
      padding: 4px 8px;
      border: 1px solid #e9ecef;
      background: #f8f9fa;
      border-radius: 0.375rem;
      -webkit-border-radius: 0.375rem;
      -moz-border-radius: 0.375rem;
      -ms-border-radius: 0.375rem;
      -o-border-radius: 0.375rem;
      font-size: 12.5625px;
      color: #6c757d;
      display: flex;
      align-items: center;
      height: 29px;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
      }
      &:hover {
        background: #e1e1e1;
      }
      &.disable {
        cursor: not-allowed;
      }
    }
  }
}
.pagination-li .next-arrow svg {
  width: 11px !important;
  height: 11px !important;
}
.supply-main {
  position: relative;
  z-index: 999;
  padding: 0px 20px;
  .supply-title-text {
    font-size: 18px;
    margin-bottom: 20px;
    color: #ffffff;
  }
  .common-text,
  .dist-sec .common-text {
    font-size: 14.4992px;
    line-height: 21.7488px;
    margin: 0;
  }
}
.inner-card-sec {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  .inner-card {
    box-shadow: 0 0.5rem 1.2rem rgb(189 197 209 / 20%);
    padding: 0.75rem;
    border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    -moz-border-radius: 0.75rem;
    -ms-border-radius: 0.75rem;
    -o-border-radius: 0.75rem;
    text-align: center;
    h6 {
      font-size: 22.5px;
      line-height: 27px;
      margin-bottom: 4px;
    }
    p {
      color: var(--txt-color);
      font-size: 14.4992px;
      line-height: 21.7488px;
    }
  }
}
.supply-chart-sec {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.chart-title {
  font-size: 18px;
  color: var(--txt-color);
  line-height: 18px;
  text-align: center;
}
.chart-main {
  width: 100%;
}
.w-full {
  width: 100%;
}

.center-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart-title {
  text-align: center;
  margin-bottom: 20px;
}

.custom-legend {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  max-width: 80%;
  font-size: 14px;
  margin: 20px auto 0 auto;
}

.legend-item {
  display: flex;
  align-items: center;
  width: calc(100% / 2);
}

.legend-color {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}
.dist-sec {
  padding: 10px 0;
  border-bottom: 1px solid #e9ecef;
}
.dist-sec:last-child {
  border-bottom: none;
}
.text-right {
  text-align: right;
}
.common-text {
  font-size: 14.4992px;
  line-height: 21.7488px;
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .footer-col-sec {
    gap: 15px;
  }
  .footer-col-sec .footer-logo {
    height: 60px;
    margin-bottom: 15px;
  }
  .mic .metamask-btn img {
    width: 25px;
  }
  .header__logo {
    padding: 0;
  }
  .header__logo > img {
    height: 40px;
  }
  .mic .metamask-btn {
    font-size: 0.74515625rem;
  }
  .footer-col-sec .map-img {
    margin: 0px 0 0 0;
  }
  .header__search {
    width: 320px;
  }
  .status-card__info > h4 {
    font-size: 1.5rem;
  }
  .gradient-text-sec .gradient-text {
    font-size: 40px;
    line-height: 50px;
  }
  .footer-col-sec .common-text {
    font-size: 11.5625px;
  }
  .table-wrapper th {
    font-size: 13px;
  }
  .table-wrapper td {
    font-size: 12px;
  }
  .add-box h2 {
    font-size: 18px;
  }
  .add-box h2 span {
    font-size: 22px;
  }
  .header__search > input {
    font-size: 14px;
  }
  button.btn,
  a.btn {
    font-size: 14px;
  }
  .header__search {
    height: 45px;
  }
  .footer-col .footer-link-title {
    font-size: 14px;
  }
  .footer-col-sec .pl-50 {
    padding-left: 30px;
  }
  .container-wrape .title-text {
    font-size: 22px;
  }
}
@media screen and (max-width: 991px) {
  .footer-col-sec {
    grid-template-columns: repeat(3, 1fr);
  }
  .layout-inner-wrape .info.w-40 {
    width: 50%;
  }
  ul.nav-links {
    position: fixed;
    background: #ffffff;
    top: 0;
    bottom: 0;
    left: -100%;
    z-index: 999;
    width: 100%;
    max-width: 250px;
    flex-direction: column;
    padding: 30px;
  }
  ul.nav-links.active {
    left: 0;
  }
  .close-btn-li {
    display: block !important;
    .menu-close-btn {
      min-width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .status-wrape .MuiGrid-container .MuiGrid-item {
    padding-top: 0;
  }
  .home-trans-table .table-wrapper {
    overflow-x: auto;
  }
  // .wallet-add-sec{
  //   input{
  //     width: 490px;
  //   }
  // }
  .wallet-add-wapper .wallet-add-input{
width: 490px;
  }
  .menu-btn{
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .layout-inner-wrape .info.w-40 {
    width: 100%;
  }
  .layout-inner-wrape {
    .d-flex.align-center {
      flex-direction: column;
    }
  }
  .header__search {
    width: 100%;
  }
  .header {
    padding: 10px 10px;
  }
  .header-right-wrap {
    gap: 5px;
  }
  .header__right {
    align-items: center;
    justify-content: end;
  }
  .light-dark-btn {
    margin-right: 0;
  }
  .header__search {
    height: 40px;
  }
  .header__search > i {
    right: 10px;
    font-size: 1.3rem;
  }
  .header__search > input {
    padding: 10px 40px 10px 20px;
  }
  .status-wrape.w-60 {
    width: 100%;
  }
  .small-text {
    line-height: 35px;
    font-size: 20px;
  }
  .gradient-text-sec .gradient-text {
    font-size: 30px;
    line-height: 40px;
  }

  .footer-col-sec {
    grid-template-columns: repeat(2, 1fr);
    .footer-col:first-child {
      grid-column: span 2;
    }
    .pl-50 {
      padding-left: 0px;
    }
    .footer-ul{
      // margin-bottom: 0;
      .footer-li {
        margin-bottom: 6px;
        height: 20px;
        display: flex;
    }
    }
  }
  .table-wrapper table {
    width: 100%;
    min-width: 480px;
    border-spacing: 0;
    border-left: 1px solid var(--table-border);
    border-top: 1px solid var(--table-border);
}
.footer-wrapper {
  padding: 24px 1rem;
}
.home-trans-table .table-wrapper {
  overflow-x: auto;
}
.card-header .btn-sec {
  gap: 10px;
}
.header .header__right {
  gap: 5px;
}
.container-wrape .title-text {
  font-size: 18px;
  margin-bottom: 20px;
}
.card .card__header h3 {
  font-size: 1rem;
}
.block-info > p {
  padding: 8px 10px 8px;
  word-break: break-all;
  font-size: 12px;
  display: flex;
          flex-direction: column;
}
.block-info > p span {
  width: 90px;
  font-size: 13px;
}
.add-box h2 {
  font-size: 16px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.add-box h2 span {
  font-size: 20px;
  margin-bottom: 10px;
}
.wallet-add-sec {
  width: 100%;
}
.wallet-add-wapper .wallet-add-input {
  width: 332px;
}
.button-wrape .card-title{
font-size: 18px;
}
.quis-sec h5{
font-size: 16px;
}
.quis-sec p{
font-size: 14px;
}
}

