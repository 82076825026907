.status-wrap {
  margin-bottom: 30px;
}
.status-card {
  padding: 1.75rem;
  display: flex;
  align-items: center;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease 0s;
  margin-bottom: 20px;
  &::before {
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    // background-image: linear-gradient(
    //   to top right,
    //   var(--main-color),
    //   var(--second-color)
    // );
    background-image: linear-gradient(-108deg, #5db64c, #115d37);
    position: absolute;
    left: -50%;
    top: 0;
    transform: scale(0);
    transition: transform 0.8s ease 0s;
  }
  &::after {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to top right,
      var(--main-color),
      var(--second-color)
    );
  }
  &:hover {
    color: var(--txt-white);
    span {
      color: var(--txt-white);
    }
    &::before {
      transform: scale(3);
    }
  }
}

.status-card__icon {
  width: 60px;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
}

.status-card__info {
  flex-grow: 1;
  // text-align: center;
  z-index: 1;
  text-transform: capitalize;
}
.status-card__info > span {
  color: var(--main-color);
}
.status-card__info > h4 {
  font-size: 2.25rem;
}

@media only screen and (max-width: 1260px) {
  .status-card {
    padding: 1.75rem 1.25rem;
  }
}

@media only screen and (max-width: 600px) {
  .status-card {
    font-size: 1.5rem;
  }
}
