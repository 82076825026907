
.header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: var(--header-height);
    // background-color: var(--main-bg);
    // box-shadow: var(--box-shadow);
    ul.nav-links {
        display: flex;
        gap: 15px;
        margin: 0;
        a {
            padding: 10px;
            position: relative;
            border-bottom: 3px solid transparent;
            &:hover, &.active {
                border-color: var(--main-color);
            }
        }
        button{
            // font-size: 18px;
            color: #000000;
            text-transform: capitalize;
            font-family: "MonaSans-Medium";
        }
    }
}
.theme-mode-dark ul.nav-links li .nav-items{
    color: var(--txt-color);
}
.header__search {
    position: relative;
    height: 50px;
    width: 380px;
    background-color: var(--main-bg);
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.header__search > input {
    height: 100%;
    width: 100%;
    padding: 10px 60px 10px 20px;
    font-size: 1rem;
    border-radius: var(--border-radius);
    color: var(--txt-color);
    background-color: var(--main-bg);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    -ms-border-radius: var(--border-radius);
    -o-border-radius: var(--border-radius);
}

.header__search > i {
    font-size: 1.5rem;
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.header__left {
    display: flex;
    align-items: flex-start;
}

.header__center{
    display: flex;
    align-items: center;
    a:hover{
        color: var(--txt-color);
    }
}

.header__right {
    display: flex;
    align-items: center;
    gap: 10px;
    // margin-right: 1.5rem;
    margin-left: 10px;
}

.header__right-item ~ .header__right-item {
    margin-left: 30px;
}

.header__logo {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    &:hover{
        // color: var(--second-color-orange);
    }
    h1 {
        font-size: 20px;
        line-height: 20px;
        padding-left: 10px;
        margin: 0;
        span{
            font-size: 15px;
        }
    }
}
.header-right-sec{
    display: flex;
    align-items: center;
}
.header__logo > img {
    height: 50px;
}

@media only screen and (max-width: 1256px) {
    .header__logo {
        font-size: 0px;
    }
    .header__search {
        width: 219px;
    }
}

@media only screen and (max-width: 1365px) {
    .header__search {
        width: 350px;
    }
}
@media only screen and (max-width: 1200px) {
    .header__right {
        margin-right: 0;
    }
    .header__logo {
        padding: 0px 10px;
    }
}

@media only screen and (max-width: 900px) {
    .header__logo h1 {
        display: none;
    }
    .header__right {
        margin-right: 0;
    }
}


@media only screen and (max-width: 600px) {
    .header {
        .header__logo > img {
            height: 40px;
        }
        ul.nav-links {
            li {
                font-size: 15px;
                
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .header {
        ul.nav-links {
            grid-gap: 15px;
            gap: 15px;
            li {
                font-size: 14px;
                a {
                    padding: 10px 0;
                }
            }
        }
        .header__right {
            grid-gap: 0px;
            gap: 0px;
        }
        .header__right-item {
            .theme_menu__toggle {
                position: fixed;
                right: 0;
                top: 120px;
                z-index: 10;
                background: var(--main-bg);
                border-radius: 10px 0 0 10px;
                padding: 8px 5px 8px 10px;
                line-height: 1;
                box-shadow: var(--box-shadow);
                i {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
.close-btn-li{
    display: none !important;
}
.header-sub-ul{
    display: none;
}
.nav-links li {
    
    .header-sub-ul {
        padding: 10px;
    position: absolute;
    top: 34px;
    background: var(--main-bg);
    z-index: 9;
    left: 0;
    border-radius: 10px;
    color: var(--txt-color);
    width: max-content;
    display: none;
    .header-sub-link{
        font-size: .8203125rem;
        border-radius: .375rem;
        padding: 0.375rem 0.75rem;
        color: var(--txt-color);
    }
    }
    &:hover .header-sub-ul{
        display: block;
    }
}

.nav-links li {
    // position: relative;
    // display: flex;
    // align-items: center;
}
.nav-links li{
    //font-size: 18px;
    //color: #000000;
    //text-transform: capitalize;
    //font-family: "MonaSans-Medium";
    display: flex;
    align-items: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.nav-links li .header-sub-li:hover{
    background: #e9e9e9;
}
.sub-menu-li{
    font-size: 14px;
    color: var(--txt-color);
    text-transform: capitalize;
    font-family: "MonaSans-Medium";
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    margin-bottom: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.nav-links li .header-sub-link:hover{
    text-decoration: none;
}
.header ul.nav-links .header-sub-li a:hover,
.header ul.nav-links .header-sub-li a{
    border: none;
}