::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 5px;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-track);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--scroll-bar-bg);
  border-radius: 0px;
}

.theme-mode-lightt {
  --main-bg: var(--main-bg-light);
  --second-bg: var(--second-bg-light);
  --txt-color: var(--txt-color-light);
  --box-shadow: var(--box-shadow-light);
}

.theme-mode-dark {
  --main-bg: var(--main-bg-dark);
  --second-bg: var(--second-bg-dark);
  --txt-color: var(--txt-color-dark);
  --box-shadow: var(--box-shadow-dark);
  --table-border: var(--table-border-dark);
  --table-th-bg: var(--second-bg-dark);
  --table-th-border: var(--table-border-dark);
  --input-color: var(--second-bg-light);
  --input-border: var(--input-border-dark);
  --place-holder: var(--white-place-holder);
  --field-border: var(--field-border-dark);
  --text-input-field: var(--second-bg-light);
  --accr-base-color: var(--accr-base-color-dark);
  --accr-button-color: var(--accr-button-color-dark);
  --field-border: var(--field-border-dark);
  --lable-bold-color: var(--main-bg-light);
  --second-bg-light: var(--second-bg-dark);
  --text-input-field: var(--main-bg-light);
  --input-color: var(--txt-color-dark);
  --text-disable: var(--text-disable-dark);
}

.theme-color-blue {
  --main-color: var(--main-color-blue);
  --second-color: var(--second-color-blue);
  --title-text-color: var(--second-color-blue);
  --checkbox-select: var(--second-color-blue);
  --scroll-bar-track: var(--second-color-blue);
}

.theme-color-red {
  --main-color: var(--main-color-red);
  --second-color: var(--second-color-red);
  --title-text-color: var(--second-color-red);
  --checkbox-select: var(--second-color-red);
  --scroll-bar-track: var(--second-color-red);
}

.theme-color-cyan {
  --main-color: var(--main-color-cyan);
  --second-color: var(--second-color-cyan);
  --title-text-color: var(--second-color-cyan);
  --checkbox-select: var(--second-color-cyan);
  --scroll-bar-track: var(--second-color-cyan);
}

.theme-color-green {
  --main-color: var(--main-color-green);
  --second-color: var(--second-color-green);
  --title-text-color: var(--second-color-green);
  --checkbox-select: var(--second-color-green);
  --scroll-bar-track: var(--second-color-green);
}

.theme-color-orange {
  --main-color: var(--main-color-orange);
  --second-color: var(--second-color-orange);
  --scroll-bar-track: var(--second-color-orange);
}

.theme-mode-dark.theme-color-orange {
  --checkbox-select: var(--second-color-orange);
  --link-text: var(--second-color-orange);
}
.theme-mode-dark.theme-color-green {
  --link-text: var(--second-color-green);
}
.theme-mode-dark.theme-color-cyan {
  --link-text: var(--second-color-cyan);
}
.theme-mode-dark.theme-color-red {
  --link-text: var(--second-color-red);
}
.theme-mode-dark.theme-color-blue {
  --link-text: var(--second-color-blue);
}
