.footer-main {
  padding: 15px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--main-bg);
  p {
    color: #fff;
    margin-bottom: 0;
    font-size: 15px;
  }
  ul li {
    padding: 0 30px;
    a {
      font-size: 15px;
      color: #fff;
      font-weight: 500;
    }
  }
}
.theme-mode-light .footer-main p,
.theme-mode-light .footer-main ul li a {
  color: var(--txt-color);
}
.footer-wrapper{
  background: var(--main-bg);
  padding: 24px 2.75rem;
  .footer-link-sec{
    // padding-bottom: 24px;
    // border-bottom: 1px solid #e9ecef;
    padding: 24px 0;
    border-top: 1px solid #e9ecef;
    margin: 20px 0 0 0;
    .social-link-sec{
      display: flex;
      gap: 10px;
      .social-link{
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #e9ecef;
    img{
      width: 15px;
      height: 15px;
  
    }
    }
    }
  }
}
.footer-col-sec{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  padding: 40px 0 !important;
  .footer-logo{
    height: 80px;
    margin-bottom: 25px;
  }
  .common-text{
    font-size: 12.5625px;
    line-height: 18.8438px;
  }
  .map-img{
    width: 100%;
    max-width: 280px;
    max-height: 168.3px;
    height: 100%;
    margin: 20px 0 0 0;
    object-fit: contain;
  }
  .footer-ul .footer-li{
    margin-bottom: 12px;
  }
  .footer-ul .footer-li:last-child{
    margin-bottom: 0;
  }
  .pl-50{
    padding-left: 50px;
  }
}
