.first_Address {
  padding: 20px;
  margin-left: -5px;
}

.address_card {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.ABI_card {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.ABI_endcode {
  display: flex;
  flex-direction: column;
}

.ABI-encoded {
  display: flex;
  align-content: center;
  justify-content: space-around;
  margin-bottom: 00px;
}

.ABI_info {
  display: flex;
  justify-content: start;
}

.button_card {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

// .first-btn {
//   background-color: #f7971c !important;
//   color: black !important;
// }

// .second-btn {
//   background-color: red !important;
//   color: white !important;
// }

// .third-btn {
//   background-color: #66a8e1 !important;
//   color: white !important;
// }