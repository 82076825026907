.chart {
  min-height: 350px;
}
.d-flex {
  display: flex;
  .w-40 {
    width: 40%;
  }
  .w-60 {
    width: 60%;
  }
  .info {
    padding: 0 15px;
  }
}
.info-wrape {
  background-image: linear-gradient(to right, #a22ffc, #2655e9);
}

.status-wrap {
  display: flex;
  align-items: center;
  padding: 50px 20px;
  // background: rgba(0, 0, 0, 0.25);
  // box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
  border-top-left-radius: 10px;
  margin-top: 50px;
  position: relative;
  &::after {
    background-color: rgba(0, 0, 0, 0.25);
    // top: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    bottom: -16px;
    position: absolute;
    width: 9999px;
    z-index: 0;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
    border-top-left-radius: 10px;
  }
}

.chart-bg {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}
.status-card {
  padding: 20px 12px 20px 20px;
  // background-color: transparent;
  box-shadow: none;
}
.mt-30 {
  padding-top: 30px;
}
.theme-mode-light .status-wrap .status-card__info h4,
.theme-mode-dark .status-wrap .status-card__info h4,
.theme-mode-light .chart .apexcharts-legend-text,
.theme-mode-light .chart .apexcharts-text {
  color: #fff !important;
}
.apexcharts-theme-light .apexcharts-menu-icon svg,
.apexcharts-theme-light .apexcharts-pan-icon svg {
  fill: #f3f4f5;
}
.apexcharts-theme-light .apexcharts-zoom-icon svg,
.apexcharts-theme-light .apexcharts-zoomin-icon svg,
.apexcharts-theme-light .apexcharts-zoomout-icon svg,
.apexcharts-theme-light .apexcharts-reset-icon svg,
.apexcharts-theme-light .apexcharts-pan-icon svg,
.apexcharts-theme-light .apexcharts-selection-icon svg,
.apexcharts-theme-light .apexcharts-menu-icon svg,
.apexcharts-theme-light .apexcharts-toolbar-custom-icon svg,
.apexcharts-theme-light .apexcharts-yaxis-label,
.apexcharts-theme-light .apexcharts-xaxis-label {
  fill: #f3f4f5;
}
.home-trans-table .table-wrapper {
  overflow-x: hidden;
}
.status-card__info {
  span {
    font-size: 12px;
  }
  h4 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1256px) {
  .row {
    .col-6 {
      width: 100%;
      font-size: 12px;
    }
  }
  // .chart {
  //     min-height: 600px;
  // }
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 1.5rem;
  }
  .info {
    .col-6 {
      width: 100%;
    }
  }
}
.top-title-sec .top-title {
  font-size: 40px;
  line-height: 47px;
  font-family: "MonaSans-Regular";
  color: var(--txt-color);
  letter-spacing: 0;
}
.btn-sec .refresh-btn {
  border: 1px solid #43964d;
  border-radius: 18px;
  font-size: 14px;
  text-transform: capitalize;
  color: #000;
  padding: 8.5px 30px;
  line-height: 20px;
}
.layout.theme-mode-dark .btn-sec .refresh-btn{
  color: #FFFFFF;
}
.btn-sec .refresh-btn.active {
  background-image: linear-gradient(-108deg, #5db64c, #115d37);
  color: #fff;
}
.top-title-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-bottom: none;
}
.card-header .btn-sec {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.gradient-text-sec .gradient-text {
  font-size: 50px;
  background: -webkit-linear-gradient(266deg, #5db64c, #115d37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "MonaSans-SemiBold";
  margin: 0;
  line-height: 60px;
}
.card__body .gradient-text-sec {
  margin: 40px 0;
}
.gradient-text-sec .gradient-text span {
  color: #000000;
  font-size: 35px;
  font-family: "MonaSans-Medium";
}
.gradient-text-sec.d-flex {
  display: flex;
  align-items: end;
  gap: 12px;
}
.small-text {
  font-size: 30px;
}
.align-center{
  align-items: center;
}
