.table-wrapper {
    overflow-y: hidden;

    table {
        width: 100%;
        min-width: 400px;
        border-spacing: 0;
        border-left: 1px solid var(--table-border);
        border-top: 1px solid var(--table-border);
    }
    
    thead {
        background-color: var(--table-th-bg);
        // background-color: var(--main-bg-dark);
        // color: var(--text-white);
    }
    
    tr {
        text-align: left;
        position: relative;
    }
    
    th{
        text-transform: capitalize;
        padding: 15px 10px;
        border-right: 1px solid var(--table-th-border);
        border-bottom: 1px solid var(--table-border);
        font-size: 15px;
    }
    td {
        // text-transform: capitalize;
        padding: 15px 10px;
        border-right: 1px solid var(--table-border);
        border-bottom: 1px solid var(--table-border);
        font-size: 14px;
    }
    
    tbody > tr:hover {
        //background-color: #5db64c;
        background-image: linear-gradient(321deg, rgba(86, 175, 130, 0.7215686275), #5db64c);
        background-image: linear-gradient(-108deg, #5db64c, #115d37);
        color: var(--txt-white);
       
        cursor: pointer;
        a{
            color: var(--txt-white);
        }
    }
    tbody > tr:hover {
        td {
            // border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
        td:first-child{
            border-radius: 5px 0 0 5px;
            -webkit-border-radius: 5px 0 0 5px;
            -moz-border-radius: 5px 0 0 5px;
            -ms-border-radius: 5px 0 0 5px;
            -o-border-radius: 5px 0 0 5px;
        }
        td:last-child{
            border-radius: 0 5px 5px 0;
            -webkit-border-radius: 0 5px 5px 0;
            -moz-border-radius: 0 5px 5px 0;
            -ms-border-radius: 0 5px 5px 0;
            -o-border-radius: 0 5px 5px 0;
}
    }
}

.table__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    h3 {
        font-size: 0.925rem;
        font-weight: 400;
    }
}

.table__pagination-item ~ .table__pagination-item {
    margin-left: 10px;
}

.table__pagination-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.table__pagination-item.active, .table__pagination-item.active:hover {
    background-color: var(--main-color);
    color: var(--txt-white);
    font-weight: 600;
}

.table__pagination-item :hover {
    color: var(--txt-white);
    background-color: var(--second-color);
}

.btn-wrape {
    display: flex;
    gap: 10px;
    button {
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-bottom: 0;
        i {
            font-size: 18px;
            line-height: 1;
        }
    }
}
.api-key-table {
    .table-wrapper table {
        border-top: none;
        border-left: none;
    }
    .table-wrapper thead{
        background: transparent;
    }
    .table-wrapper th{
        border-right: none;
    }
    .table-wrapper td{
        border-right: none;
    }
}